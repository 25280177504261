import axios from "axios";

                        //  export const baseURL = `http://192.168.1.7:3001/api/`;
  //  export const baseURL = `http://15.206.15.78/api/`; //fids
  // export const baseURL = `http://43.204.45.193/api/`; //retro
                        export const baseURL = `https://greenapple.reporterp.com/api/`; //green apple
// http://13.235.42.126/api/ ;//test server
export const headers = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${window.localStorage.getItem("token")}`,
};
const instance = axios.create({
  baseURL: baseURL,
  headers: headers,
  responseType: "json",
});
function createAxiosResponseInterceptor(axiosInstance) {
  const interceptor = axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }
      axiosInstance.interceptors.response.eject(interceptor);
      window.localStorage.clear();
      window.location.replace("/login");
      return Promise.reject(error);
    }
  );
}

createAxiosResponseInterceptor(instance);

export default instance;

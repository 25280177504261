import API from "../../../global/api";

import logo from "../../../Images/logo.png";
import jsPDF from "jspdf";
import { appendNonSignificantZeros } from "../../../global/globalFunctions";
import moment from "moment";
import { isEmpty, map,sumBy, isNull } from "lodash";
import { push } from "connected-react-router";
import {
  getLevel4Data,
  requestLevel4GetData,
} from "../forms/accountsLevels/accountsLevel.action";
//Action types
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const SET_MODULE_KEY = "SET_MODULE_KEY";

// Request Actions

export const loginUserRequest = () => {
  return {
    type: "LOGIN_USER_REQUEST",
  };
};
export const loginUserSuccess = (data) => {
  return {
    type: "LOGIN_USER_SUCCESS",
    payload: data,
  };
};
export const loginUserError = () => {
  return {
    type: "LOGIN_USER_ERROR",
  };
};

export const setModuleKey = (data) => {
  return {
    type: "SET_MODULE_KEY",
    payload: data,
  };
};

export const getLogin = (username) => {
  return (dispatch) => {
    API.post(`/users/authenticate`, username)
      .then((res) => {
        let data1 = res.data;
        dispatch(loginUserSuccess(data1));

        window.localStorage.setItem("res", res.message);
        window.localStorage.setItem("token", data1.token);
        window.localStorage.setItem("userId", data1.id);
        window.localStorage.setItem("isAuthenticatedUser", "true");

        window.localStorage.setItem("isUser", "true");
        window.localStorage.setItem("isAdmin", false);

        window.localStorage.setItem("isAuthenticated", "true");
        window.localStorage.setItem(
          "permissions",
          JSON.stringify(data1.Permissions)
        );

        window.location.replace("/home");
      })
      .catch((error) => {
        dispatch(loginUserError());
      });
  };
};

const sumArray = (array) => {
  const newArray = [];
  array.forEach((sub) => {
    sub.forEach((num, index) => {
      if (newArray[index]) {
        newArray[index] += num;
      } else {
        newArray[index] = num;
      }
    });
  });
  return newArray;
};

export const requestTrialData = () => {
  return {
    type: "REQUEST_TRIAL_DATA",
  };
};
export const successTrialData = (data) => {
  return {
    type: "SUCCESS_TRIAL_DATA",
    payload: data,
  };
};
export const errorTrialData = () => {
  return {
    type: "ERROR_TRIAL_DATA",
  };
};

export const getTrialData = (data) => {
  return (dispatch) => {
    dispatch(requestTrialData());
    API.post(`/trial_balance/get_trial_balance`, data)
      .then((res) => {
        let getData = res.data;
        dispatch(successTrialData(getData));
      })
      .catch((error) => {
        dispatch(errorTrialData());
      });
  };
};
export const getTrialDataPdf = (data) => {
  return (dispatch) => {
    console.log(data, "checkDate");
    dispatch(requestTrialData());
    API.post(`/trial_balance/get_trial_balance_pdf`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "ccc");
        dispatch(successTrialData(getData));
        console.log(getData, "trialData");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape
         let total=sumBy(getData,"levelBalance")
         console.log(total,"abcdefgh")
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        let yMargin = 190;
        doc.setFontSize(14);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const Dates =
          !isEmpty(data.startedDate) || !isEmpty(data.endDate)
            ? "Form: " + " " + data.startedDate + " to " + data.endDate
            : "Form: -  To: - ";
        doc.addImage(logo, "PNG", 40, 35, 70, 70);
        doc.setFont("Times New Roman", "bolditalic");
        doc.text(companyName, 225, 60, 0, 20);
        doc.text("Trial Balance Report", 225, 75, 0, 20);
        doc.text(Dates, 200, 90, 0, 20);
        doc.text(`Grand Total : ${total.toLocaleString()}`,400,110)
        map(getData, (x, index) => {
          console.log(index, "trialDatamap");
          console.log(x, "pdfData");
          let contentLevelTwo = {
            startY: index == 0 ? 130 : doc.lastAutoTable.finalY + 15,
            startX: 10,
            head: [
              [
                `${x.level_twos[0].allKey}-${x.level_twos[0].levelTwoName}`,
                `${x.level_twos[0].levelBalance.toLocaleString()}`,
              ],
            ],
            body: [],
            columnStyles: {
              0: { cellWidth: 500 },
              1: { cellWidth: 100 },
            },
          };
          doc.autoTable(contentLevelTwo);
          map(x.level_twos[0].level_threes, (x3, index3) => {
            console.log(x3, "kkkkkkkkkkkkkkkkkkkkkkkkk");
   
            let contentLevelthree = {
              startY:
                index3 == 0
                  ? doc.lastAutoTable.finalY + 2
                  : doc.lastAutoTable.finalY + 30,
              startX: 10,
              
              head: [
                [`${x3.allLvlKey}-${x3.levelThreeName}`, `${x3.levelBalance.toLocaleString()}`],
              ],
              body: [],
              columnStyles: {
                0: { cellWidth: 500 },
                1: { cellWidth: 100 },
              },
              // headStyles: { fillColor: [255,255,255], color: }
            };
            doc.autoTable(contentLevelthree);
            map(x3.level_fours, (x4, index4) => {
              let contentLevelFour = {
                startY:
                  index4 == 0
                    ? doc.lastAutoTable.finalY + 2
                    : doc.lastAutoTable.finalY + 5,
                startX: 10,
                head: [
                  [
                    `${x4.allLevlKey}-${x4.levelFourName}`,
                    `${x4.levelBalance.toLocaleString()}`,
                  ],
                ],
                body: [],
                columnStyles: {
                  0: { cellWidth: 500 },
                  1: { cellWidth: 100 },
                },
                // headStyles: { fillColor: [220,220,220] }
              };
              doc.autoTable(contentLevelFour);
              ///----
              let data5 = [];
              let totalData = [];
              map(x4.level_fives, (x5, index5) => {
                console.log(x5, "iiiiiiiiiiiiiiiiiiiiiiiiiiiii");
                let rowData = x5;
                let newArray = [];
                let newArrayTotalData = [];
                newArray.push(x5.allLevelKey);
                newArray.push(x5.levelFiveName);
                // newArray.push(
                //   x5.transactions[0].opening_balance_sign == 2 ? !isEmpty(x5) && x5.transactions[0].opening_balance : "a"
                // );
                newArray.push(
                  Number(x5.transactions[0].opening_balance_sign) == 2
                    ? `${x5.transactions[0].opening_balance}`
                    : 0
                );
                newArray.push(
                  Number(x5.transactions[0].opening_balance_sign) == 1
                    ? `${x5.transactions[0].opening_balance}`
                    : 0
                );
                newArray.push(
                  `${x5.transactions[0].debit_sum}`

                  //  Number(x5.transactions[0].opening_balance_sign)==1 ? `${x5.transactions[0].opening_balance}` : 0
                  // !isEmpty(x5.voucher_ledgers)
                  //   ? x5.voucher_ledgers
                  //       .map((xy) => (!isNull(xy.debit) ? Number(xy.debit) : 0))
                  //       .reduce((a, b) => a + b)
                  //   : 0
                );
                newArray.push(
                  `${x5.transactions[0].credit_sum}`
                  // !isEmpty(x5.voucher_ledgers)
                  //   ? x5.voucher_ledgers
                  //       .map((xy) =>
                  //         !isNull(xy.credit) ? Number(xy.credit) : 0
                  //       )
                  //       .reduce((a, b) => a + b)
                  //   : 0
                );
                newArray.push(
                  Number(x5.transactions[0].closing_balance_sign) == 2
                    ? `${x5.transactions[0].closing_balance}`
                    : 0
                  // (rowData.open_blc_type == 2 &&
                  //   (rowData.open_blc_type == 1
                  //     ? (rowData.open_blc_type == 1
                  //         ? !isEmpty(rowData) && rowData.open_blc
                  //         : 0) +
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.credit) ? Number(x.credit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0) -
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.debit) ? Number(x.debit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0)
                  //     : (rowData.open_blc_type == 2
                  //         ? !isEmpty(rowData) && rowData.open_blc
                  //         : 0) +
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.debit) ? Number(x.debit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0) -
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.credit) ? Number(x.credit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0)) > 0) ||
                  //   (rowData.open_blc_type == 1 &&
                  //     (rowData.open_blc_type == 1
                  //       ? (rowData.open_blc_type == 1
                  //           ? !isEmpty(rowData) && rowData.open_blc
                  //           : 0) +
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.credit) ? Number(x.credit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0) -
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.debit) ? Number(x.debit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0)
                  //       : (rowData.open_blc_type == 2
                  //           ? !isEmpty(rowData) && rowData.open_blc
                  //           : 0) +
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.debit) ? Number(x.debit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0) -
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.credit) ? Number(x.credit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0)) < 0)
                  //   ? Math.abs(
                  //       rowData.open_blc_type == 1
                  //         ? (rowData.open_blc_type == 1
                  //             ? !isEmpty(rowData) && rowData.open_blc
                  //             : 0) +
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.credit) ? Number(x.credit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0) -
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.debit) ? Number(x.debit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0)
                  //         : (rowData.open_blc_type == 2
                  //             ? !isEmpty(rowData) && rowData.open_blc
                  //             : 0) +
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.debit) ? Number(x.debit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0) -
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.credit) ? Number(x.credit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0)
                  //     )
                  //   : 0
                );
                newArray.push(
                  Number(x5.transactions[0].closing_balance_sign) == 1
                    ? `${x5.transactions[0].closing_balance}`
                    : 0
                  // (rowData.open_blc_type == 1 &&
                  //   (rowData.open_blc_type == 1
                  //     ? (rowData.open_blc_type == 1
                  //         ? !isEmpty(rowData) && rowData.open_blc
                  //         : 0) +
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.credit) ? Number(x.credit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0) -
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.debit) ? Number(x.debit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0)
                  //     : (rowData.open_blc_type == 2
                  //         ? !isEmpty(rowData) && rowData.open_blc
                  //         : 0) +
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.debit) ? Number(x.debit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0) -
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.credit) ? Number(x.credit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0)) > 0) ||
                  //   (rowData.open_blc_type == 2 &&
                  //     (rowData.open_blc_type == 1
                  //       ? (rowData.open_blc_type == 1
                  //           ? !isEmpty(rowData) && rowData.open_blc
                  //           : 0) +
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.credit) ? Number(x.credit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0) -
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.debit) ? Number(x.debit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0)
                  //       : (rowData.open_blc_type == 2
                  //           ? !isEmpty(rowData) && rowData.open_blc
                  //           : 0) +
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.debit) ? Number(x.debit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0) -
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.credit) ? Number(x.credit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0)) < 0)
                  //   ? Math.abs(
                  //       rowData.open_blc_type == 1
                  //         ? (rowData.open_blc_type == 1
                  //             ? !isEmpty(rowData) && rowData.open_blc
                  //             : 0) +
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.credit) ? Number(x.credit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0) -
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.debit) ? Number(x.debit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0)
                  //         : (rowData.open_blc_type == 2
                  //             ? !isEmpty(rowData) && rowData.open_blc
                  //             : 0) +
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.debit) ? Number(x.debit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0) -
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.credit) ? Number(x.credit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0)
                  //     )
                  //   : 0
                );
                data5.push(newArray);
                ///Adding For getting Total
                newArrayTotalData.push(
                  Number(x5.transactions[0].opening_balance_sign) == 2
                    ? x5.transactions[0].opening_balance
                    : 0

                  // x5.open_blc_type == 2 ? !isEmpty(x5) && x5.open_blc : 0
                );
                newArrayTotalData.push(
                  Number(x5.transactions[0].opening_balance_sign) == 1
                    ? x5.transactions[0].opening_balance
                    : 0
                  // x5.open_blc_type == 1 ? !isEmpty(x5) && x5.open_blc : 0
                );
                newArrayTotalData.push(
                  x5.transactions[0].debit_sum
                  // !isEmpty(x5.voucher_ledgers)
                  //   ? x5.voucher_ledgers
                  //       .map((xy) => (!isNull(xy.debit) ? Number(xy.debit) : 0))
                  //       .reduce((a, b) => a + b)
                  //   : 0
                );
                newArrayTotalData.push(
                  x5.transactions[0].credit_sum
                  // !isEmpty(x5.voucher_ledgers)
                  //   ? x5.voucher_ledgers
                  //       .map((xy) =>
                  //         !isNull(xy.credit) ? Number(xy.credit) : 0
                  //       )
                  //       .reduce((a, b) => a + b)
                  //   : 0
                );
                newArrayTotalData.push(
                  Number(x5.transactions[0].closing_balance_sign) == 2
                    ? x5.transactions[0].closing_balance
                    : 0
                  // (rowData.open_blc_type == 2 &&
                  //   (rowData.open_blc_type == 1
                  //     ? (rowData.open_blc_type == 1
                  //         ? !isEmpty(rowData) && rowData.open_blc
                  //         : 0) +
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.credit) ? Number(x.credit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0) -
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.debit) ? Number(x.debit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0)
                  //     : (rowData.open_blc_type == 2
                  //         ? !isEmpty(rowData) && rowData.open_blc
                  //         : 0) +
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.debit) ? Number(x.debit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0) -
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.credit) ? Number(x.credit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0)) > 0) ||
                  //   (rowData.open_blc_type == 1 &&
                  //     (rowData.open_blc_type == 1
                  //       ? (rowData.open_blc_type == 1
                  //           ? !isEmpty(rowData) && rowData.open_blc
                  //           : 0) +
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.credit) ? Number(x.credit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0) -
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.debit) ? Number(x.debit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0)
                  //       : (rowData.open_blc_type == 2
                  //           ? !isEmpty(rowData) && rowData.open_blc
                  //           : 0) +
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.debit) ? Number(x.debit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0) -
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.credit) ? Number(x.credit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0)) < 0)
                  //   ? Math.abs(
                  //       rowData.open_blc_type == 1
                  //         ? (rowData.open_blc_type == 1
                  //             ? !isEmpty(rowData) && rowData.open_blc
                  //             : 0) +
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.credit) ? Number(x.credit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0) -
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.debit) ? Number(x.debit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0)
                  //         : (rowData.open_blc_type == 2
                  //             ? !isEmpty(rowData) && rowData.open_blc
                  //             : 0) +
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.debit) ? Number(x.debit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0) -
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.credit) ? Number(x.credit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0)
                  //     )
                  //   : 0
                );
                newArrayTotalData.push(
                  Number(x5.transactions[0].closing_balance_sign) == 1
                    ? x5.transactions[0].closing_balance
                    : 0
                  // (rowData.open_blc_type == 1 &&
                  //   (rowData.open_blc_type == 1
                  //     ? (rowData.open_blc_type == 1
                  //         ? !isEmpty(rowData) && rowData.open_blc
                  //         : 0) +
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.credit) ? Number(x.credit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0) -
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.debit) ? Number(x.debit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0)
                  //     : (rowData.open_blc_type == 2
                  //         ? !isEmpty(rowData) && rowData.open_blc
                  //         : 0) +
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.debit) ? Number(x.debit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0) -
                  //       (!isEmpty(rowData.voucher_ledgers)
                  //         ? rowData.voucher_ledgers
                  //             .map((x) =>
                  //               !isNull(x.credit) ? Number(x.credit) : 0
                  //             )
                  //             .reduce((a, b) => a + b)
                  //         : 0)) > 0) ||
                  //   (rowData.open_blc_type == 2 &&
                  //     (rowData.open_blc_type == 1
                  //       ? (rowData.open_blc_type == 1
                  //           ? !isEmpty(rowData) && rowData.open_blc
                  //           : 0) +
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.credit) ? Number(x.credit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0) -
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.debit) ? Number(x.debit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0)
                  //       : (rowData.open_blc_type == 2
                  //           ? !isEmpty(rowData) && rowData.open_blc
                  //           : 0) +
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.debit) ? Number(x.debit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0) -
                  //         (!isEmpty(rowData.voucher_ledgers)
                  //           ? rowData.voucher_ledgers
                  //               .map((x) =>
                  //                 !isNull(x.credit) ? Number(x.credit) : 0
                  //               )
                  //               .reduce((a, b) => a + b)
                  //           : 0)) < 0)
                  //   ? Math.abs(
                  //       rowData.open_blc_type == 1
                  //         ? (rowData.open_blc_type == 1
                  //             ? !isEmpty(rowData) && rowData.open_blc
                  //             : 0) +
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.credit) ? Number(x.credit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0) -
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.debit) ? Number(x.debit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0)
                  //         : (rowData.open_blc_type == 2
                  //             ? !isEmpty(rowData) && rowData.open_blc
                  //             : 0) +
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.debit) ? Number(x.debit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0) -
                  //             (!isEmpty(rowData.voucher_ledgers)
                  //               ? rowData.voucher_ledgers
                  //                   .map((x) =>
                  //                     !isNull(x.credit) ? Number(x.credit) : 0
                  //                   )
                  //                   .reduce((a, b) => a + b)
                  //               : 0)
                  //     )
                  //   : 0
                );
                totalData.push(newArrayTotalData);
                // let dt= x4.level_fives
              });

              totalData = sumArray(totalData);
              !isEmpty(data5) &&
                data5.push([
                  "",
                  "",
                  `${totalData[0]}`,
                  `${totalData[1]}`,
                  `${totalData[2]}`,
                  `${totalData[3]}`,
                  `${totalData[4]}`,
                  `${totalData[5]}`,
                  `${totalData[6]}`,
                ]);

              let contentLevelFive = {
                startY: doc.lastAutoTable.finalY + 2,
                startX: 10,
                head: [
                  [
                    "CODE",
                    "DESCRIPTIPON",
                    "OPENING DR",
                    "OPENING CR",
                    "DURING DR",
                    "DURING CR",
                    "CLOSING DR",
                    "CLOSING CR",
                  ],
                ],
                body: data5,
                tableLineColor: [189, 195, 199],
                tableLineWidth: 0.75,
                // theme: "grid",
                alternateRowStyles: { fillColor: [231, 215, 252] },
                //   headStyles: { fillColor: [210, 232, 16] }
                // headStyles: {
                //   fillColor: [139, 0, 0],
                // },
              };
              doc.autoTable(contentLevelFive);
             
              //  !isEmpty(data5) && doc.text(`Total Closing Balance: ${totalData[0]}, ${totalData[1]}, ${totalData[2]},${totalData[3]}, ${totalData[4]}, ${totalData[5]}`,50, doc.lastAutoTable.finalY + 15)
              ///----
              // console.log(doc.getVerticalCoordinateString(), "Eee")
              //  doc.text("Total",200, doc.lastAutoTable.finalY + 46);

              // closing of last map })
            });
          });
        });
        doc.save(
          `Trial Balance Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );
      })
      .catch((error) => {
        dispatch(errorTrialData());
      });
  };
};
export const getChartOfAccountPdf = (data) => {
  return (dispatch) => {
    console.log(data, "checkDate");
    dispatch(requestTrialData());
    API.post(`/trial_balance/get_trial_balance_pdf`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "ccc");
        dispatch(successTrialData(getData));
        console.log(getData, "trialData");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape
        let total=sumBy(getData,"levelBalance")
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        let yMargin = 190;
        doc.setFontSize(14);
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const Dates =
          !isEmpty(data.startedDate) || !isEmpty(data.endDate)
            ? "Form: " +
             
              moment(data.startedDate).format("DD-MM-YYYY") +
              " to " +
              moment(data.endDate).format("DD-MM-YYYY")
            : "Form: -  To: - ";
        doc.addImage(logo, "PNG", 40, 35, 70, 70);
        doc.setFont("Times New Roman", "bolditalic");
        doc.text(companyName, 225, 60, 0, 20);
        doc.text("Chart of Account", 225, 75, 0, 20);
        doc.text(Dates, 200, 90, 0, 20);
        doc.text(`Grand Total : ${total.toLocaleString()}`,400,110)
        map(getData, (x, index) => {
          console.log(index, "trialDatamap");
          // const data = getData[0].trial_bals.map((elt) => [moment(getData[0].createdAt).format("YYYY-MM-DD"), getData[0].debitCreditId == 1 ? elt.transCredit : 0, getData[0].debitCreditId == 2 ? elt.transDebit : 0, getData[0].levelBalance, getData[0].debit_credit.accountName ])
          console.log(x, "pdfData");
          let contentLevelTwo = {
            startY: index == 0 ? 130 : doc.lastAutoTable.finalY + 15,
            startX: 10,
            head: [
              [
                `${x.level_twos[0].allKey}-${x.level_twos[0].levelTwoName}`,
                `${x.level_twos[0].levelBalance.toLocaleString()}`,
              ],
            ],
            body: [],
            columnStyles: {
              0: { cellWidth: 500 },
              1: { cellWidth: 100 },
            },
          };
          doc.autoTable(contentLevelTwo);
          map(x.level_twos[0].level_threes, (x3, index3) => {
            console.log(x3, "kkkkkkkkkkkkkkkkkkkkkkkkk");
            let contentLevelthree = {
              startY:
                index3 == 0
                  ? doc.lastAutoTable.finalY + 2
                  : doc.lastAutoTable.finalY + 30,
              startX: 10,
              head: [
                [`${x3.allLvlKey}-${x3.levelThreeName}`, `${x3.levelBalance.toLocaleString()}`],
              ],
              body: [],
              columnStyles: {
                0: { cellWidth: 500 },
                1: { cellWidth: 100 },
              },
              // headStyles: { fillColor: [255,255,255], color: }
            };
            doc.autoTable(contentLevelthree);
            map(x3.level_fours, (x4, index4) => {
              let contentLevelFour = {
                startY:
                  index4 == 0
                    ? doc.lastAutoTable.finalY + 2
                    : doc.lastAutoTable.finalY + 5,
                startX: 10,
                head: [
                  [
                    `${x4.allLevlKey}-${x4.levelFourName}`,
                    `${x4.levelBalance.toLocaleString()}`,
                  ],
                ],
                body: [],
                columnStyles: {
                  0: { cellWidth: 500 },
                  1: { cellWidth: 100 },
                },
                // headStyles: { fillColor: [220,220,220] }
              };
              doc.autoTable(contentLevelFour);
              ///----
              let data5 = [];
              let totalData = [];
              map(x4.level_fives, (x5, index5) => {
                console.log(x5, "iiiiiiiiiiiiiiiiiiiiiiiiiiiii");
                let rowData = x5;
                let newArray = [];
                let newArrayTotalData = [];
                newArray.push(x5.allLevelKey);
                newArray.push(x5.levelFiveName);

                // newArray.push(
                //   Number(x5.transactions[0].opening_balance_sign) == 2
                //     ? `${x5.transactions[0].opening_balance}`
                //     : 0
                // );
                // newArray.push(
                //   Number(x5.transactions[0].opening_balance_sign) == 1
                //     ? `${x5.transactions[0].opening_balance}`
                //     : 0
                // );
                // newArray.push(
                //   `${x5.transactions[0].debit_sum}`
                // );
                // newArray.push(
                //   `${x5.transactions[0].credit_sum}`
                // );
                newArray.push(
                  Number(x5.transactions[0].closing_balance_sign) == 2
                    ? `${x5.transactions[0].closing_balance}`
                    : 0
                );
                newArray.push(
                  Number(x5.transactions[0].closing_balance_sign) == 1
                    ? `${x5.transactions[0].closing_balance}`
                    : 0
                );
                data5.push(newArray);
                ///Adding For getting Total
                // newArrayTotalData.push(
                //   Number(x5.transactions[0].opening_balance_sign) == 2
                //     ? x5.transactions[0].opening_balance
                //     : 0

                //   // x5.open_blc_type == 2 ? !isEmpty(x5) && x5.open_blc : 0
                // );
                // newArrayTotalData.push(
                //   Number(x5.transactions[0].opening_balance_sign) == 1
                //     ? x5.transactions[0].opening_balance
                //     : 0
                //   // x5.open_blc_type == 1 ? !isEmpty(x5) && x5.open_blc : 0
                // );
                // newArrayTotalData.push(
                //   x5.transactions[0].debit_sum
                // );
                // newArrayTotalData.push(
                //   x5.transactions[0].credit_sum
                // );
                newArrayTotalData.push(
                  Number(x5.transactions[0].closing_balance_sign) == 2
                    ? x5.transactions[0].closing_balance
                    : 0
                );
                newArrayTotalData.push(
                  Number(x5.transactions[0].closing_balance_sign) == 1
                    ? x5.transactions[0].closing_balance
                    : 0
                );
                totalData.push(newArrayTotalData);
                // let dt= x4.level_fives
              });

              totalData = sumArray(totalData);
              !isEmpty(data5) &&
                data5.push([
                  "",
                  "",
                  `${totalData[0]}`,
                  `${totalData[1]}`,
                  // `${totalData[2]}`,
                  // `${totalData[3]}`,
                  // `${totalData[4]}`,
                  // `${totalData[5]}`,
                  // `${totalData[6]}`,
                ]);

              let contentLevelFive = {
                startY: doc.lastAutoTable.finalY + 2,
                startX: 10,
                head: [
                  [
                    "CODE",
                    "DESCRIPTIPON",
                    // "OPENING DR",
                    // "OPENING CR",
                    // "DURING DR",
                    // "DURING CR",
                    "Debit",
                    "Credit",
                  ],
                ],
                body: data5,
                tableLineColor: [189, 195, 199],
                tableLineWidth: 0.75,
                // theme: "grid",
                alternateRowStyles: { fillColor: [231, 215, 252] },
                //   headStyles: { fillColor: [210, 232, 16] }
                // headStyles: {
                //   fillColor: [139, 0, 0],
                // },
              };
              doc.autoTable(contentLevelFive);

              //  !isEmpty(data5) && doc.text(`Total Closing Balance: ${totalData[0]}, ${totalData[1]}, ${totalData[2]},${totalData[3]}, ${totalData[4]}, ${totalData[5]}`,50, doc.lastAutoTable.finalY + 15)
              ///----
              // console.log(doc.getVerticalCoordinateString(), "Eee")
              //  doc.text("Total",200, doc.lastAutoTable.finalY + 46);

              // closing of last map })
            });
          });
        });
        doc.save(
          `Chart of Account ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );
        
       
      })
      .catch((error) => {
        dispatch(errorTrialData());
      });
  };
};
export const requestGetLevel5 = () => {
  return {
    type: "REQUEST_GET_LEVEL_5",
  };
};
export const successGetLevel5 = (data) => {
  return {
    type: "SUCCESS_GET_LEVEL_5",
    payload: data,
  };
};
export const errorGetLevel5 = () => {
  return {
    type: "ERROR_GET_LEVEL_5",
  };
};

export const getLevel5 = () => {
  return (dispatch) => {
    dispatch(requestGetLevel5());
    API.get(`/voucher-ledger/get-acc-ledger`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetLevel5(getData));
      })
      .catch((error) => {
        dispatch(errorGetLevel5());
      });
  };
};
//////
export const requestGenerateReport = () => {
  return {
    type: "REQUEST_GENERATE_REPORT",
  };
};
export const successGenerateReport = (data) => {
  return {
    type: "SUCCESS_GENERATE_REPORT",
    payload: data,
  };
};
export const errorGenerateReport = () => {
  return {
    type: "ERROR_GENERATE_REPORT",
  };
};
/*
export const getLevel4Data = () => {
    return (dispatch) => {
        dispatch(requestLevel4GetData());
        API.get(`/levelFour/get-all-data`).then((res) => {
            let getData = res.data;
            dispatch(successLevel4GetData(getData));
        }).catch((error) => {
            dispatch(errorLevel4GetData());
        })
    };
};
 */
export const SearchGenerateReport = (Op, Cl) => {
  return (dispatch) => {
    dispatch(requestGenerateReport());
    //dispatch(requestLevel4GetData());http://192.168.1.15:3001/api/profit_loss/get_profit_and_loss
    API.post(`/profit_loss/get_profit_and_loss`)
      .then((res) => {
        console.log(Op, Cl, "aaaaaa");
        let getData = res.data;
        //    let bookingData=getData[0].booking;
        console.log(getData, "rrrrr");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        const title = "Generate Report";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        doc.addImage(logo, "PNG", 40, 5, 130, 130);
        doc.setFontSize(18);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 60, 0, 0);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 80, 0, 20);
        doc.text(companyNtn, 150, 100, 0, 20);
        doc.text(title, 260, 120, 0, 0);
        //   doc.text("Joe's MotorBike Tyres",240,20,0,0);
        doc.setLineWidth(2);
        doc.line(20, 125, 575, 125);
        doc.text("Profit and Loss Statement", 235, 140, 0, 0);
        doc.line(20, 145, 575, 145);
        doc.text("For the period ended 2022", 235, 160, 0, 0);
        let sale =
          getData.local_sale.balance +
          getData.export_sale.balance +
          getData.other_income.balance +
          getData.rebate.balance;
        let data = [
          ["", "Export Sales", getData.export_sale.balance],
          ["", "Local Sales", getData.local_sale.balance],
          ["", "Rebate", getData.rebate.balance],
          ["", "Other income", getData.other_income.balance],
          ["", "Total Sales", sale],
        ];
        const headers = [["Income", "", ""]];
        let content = {
          startY: 190,
          startX: 10,
          styles: { halign: "left", cellWidth: 180 },
          head: headers,
          body: data,
        };
        doc.autoTable(content);
        let cogs =
          Number(Op) +
          Number(getData.purchase.balance) +
          Number(getData.direct_expense.balance);
        let cogs1 = Number(cogs) - Number(Cl);
        let gp = Number(sale) - Number(cogs1);
        let data1 = [
          ["", "Opening Stock", Op],
          ["Add:", "Purchases", getData.purchase.balance],
          ["", "Direct Expense", getData.direct_expense.balance],
          ["Less:", "Closing Stock", Cl],
        ]; //,["","Total Cost of Goods Sold(COGS)","Formula=OS+SP-CS"],["","Gross Profit","Formula=TS-COGS"]]
        const headers1 = [["Less:-Cost of Goods Sold", "", ""]];
        let content1 = {
          startY: doc.lastAutoTable.finalY + 10,
          startX: 10,
          styles: { halign: "left", cellWidth: 180 },
          head: headers1,
          body: data1,
        };
        doc.autoTable(content1);
        doc.setLineWidth(2);
        doc.line(
          390,
          doc.lastAutoTable.finalY + 5,
          550,
          doc.lastAutoTable.finalY + 5
        );
        doc.setFontSize(10);
        // doc.setFont(undefined,'')
        doc.text(
          "Total Cost of Goods Sold(COGS)",
          225,
          doc.lastAutoTable.finalY + 20,
          0,
          0
        );
        doc.text(`${cogs1}`, 405, doc.lastAutoTable.finalY + 20, 0, 0);
        doc.text("Gross profit", 225, doc.lastAutoTable.finalY + 40, 0, 0);
        doc.text(`${gp}`, 405, doc.lastAutoTable.finalY + 40, 0, 0);
        doc.line(
          390,
          doc.lastAutoTable.finalY + 50,
          550,
          doc.lastAutoTable.finalY + 50
        );
        let data2 = [
          ["", "Admin", getData.admin.balance],
          ["", "Selling", getData.selling.balance],
          ["", "Financial cost", getData.financial_cost.balance],
          ["", "Other", getData.other_expense.balance],
        ]; //,["Total of operating Expense","","Formula=A+S+F+O+O"],["Profit before Tax","","Formula=GP-TOP"],["TAX EXPENSE","",""],["NET PROFIT","","Formula=PBT-TAX"]]
        const headers2 = [["Operating Expenses", "", ""]];
        let content2 = {
          startY: doc.lastAutoTable.finalY + 60,
          startX: 10,
          styles: { halign: "left", cellWidth: 180 },
          head: headers2,
          body: data2,
        };
        const te = getData.tax.balance;
        const tpe =
          Number(getData.admin.balance) +
          Number(getData.selling.balance) +
          Number(getData.financial_cost.balance) +
          Number(getData.other_expense.balance);
        const pbt = Number(gp) - Number(tpe);
        const NetProfit = Number(pbt) - Number(te);
        doc.autoTable(content2);
        doc.setLineWidth(2);
        doc.line(
          390,
          doc.lastAutoTable.finalY + 5,
          550,
          doc.lastAutoTable.finalY + 5
        );
        doc.setFontSize(10);
        doc.text(
          "Total of operating Expense",
          225,
          doc.lastAutoTable.finalY + 20,
          0,
          0
        );
        doc.text(`${tpe}`, 405, doc.lastAutoTable.finalY + 20, 0, 0);
        doc.text("Profit before Tax", 225, doc.lastAutoTable.finalY + 40, 0, 0);
        doc.text(`${pbt}`, 405, doc.lastAutoTable.finalY + 40, 0, 0);
        doc.text("TAX EXPENSE", 225, doc.lastAutoTable.finalY + 60, 0, 0);
        doc.text(`${te}`, 405, doc.lastAutoTable.finalY + 60, 0, 0);
        doc.text("NET PROFIT", 225, doc.lastAutoTable.finalY + 80, 0, 0);
        doc.text(`${NetProfit}`, 405, doc.lastAutoTable.finalY + 80, 0, 0);
        doc.setLineWidth(2);
        doc.line(
          390,
          doc.lastAutoTable.finalY + 90,
          550,
          doc.lastAutoTable.finalY + 90
        );
        doc.setProperties({
          title: `Profit Loss Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });
        //ct doc.output('dataurlnewwindow')
        doc.save(
          `Profit Loss Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );
        dispatch(successGenerateReport(getData));
      })
      .catch((error) => {
        dispatch(errorGenerateReport());
      });
  };
};
//balace sheet
export const requestGenerateBalanceSheet = () => {
  return {
    type: "REQUEST_GENERATE_BALANCE_SHEET",
  };
};
export const successGenerateBalanceSheet = (data) => {
  return {
    type: "SUCCESS_GENERATE_BALANCE_SHEET",
    payload: data,
  };
};
export const errorGenerateBalanceSheet = () => {
  return {
    type: "ERROR_GENERATE_BALANCE_SHEET",
  };
};
export const SearchGenerateBalanceSheet = (Op, Cl) => {
  return (dispatch) => {
    dispatch(requestGenerateBalanceSheet());
    //dispatch(requestLevel4GetData());http://192.168.1.15:3001/api/profit_loss/get_profit_and_loss
    API.post(`/balance_sheet/get_balance_sheet`)
      .then((res) => {
        console.log(Op, Cl, "aaaaaa");
        let getData = res.data;
        //    let bookingData=getData[0].booking;
        console.log(getData, "rrrrr");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        const title = "Report";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        doc.addImage(logo, "PNG", 40, 5, 130, 130);
        doc.setFontSize(18);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 160, 60, 0, 0);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 160, 80, 0, 20);
        doc.text(companyNtn, 160, 100, 0, 20);
        doc.text(title, 240, 120, 0, 0);
        //   doc.text("Joe's MotorBike Tyres",240,20,0,0);
        doc.setLineWidth(2);
        doc.line(20, 125, 575, 125);
        doc.text("Balance Sheet", 235, 140, 0, 0);
        doc.line(20, 145, 575, 145);
        doc.text("For the period ended 2022", 235, 160, 0, 0);
        let sale =
          getData.local_sale.balance +
          getData.export_sale.balance +
          getData.other_income.balance +
          getData.rebate.balance;
        let data = [
          ["", "Export Sales", getData.export_sale.balance],
          ["", "Local Sales", getData.local_sale.balance],
          ["", "Rebate", getData.rebate.balance],
          ["", "Other income", getData.other_income.balance],
          ["", "Total Sales", sale],
        ];
        const headers = [["Income", "", ""]];
        let content = {
          startY: 190,
          startX: 10,
          styles: { halign: "left", cellWidth: 180 },
          head: headers,
          body: data,
        };
        doc.autoTable(content);
        let cogs =
          Number(Op) +
          Number(getData.purchase.balance) +
          Number(getData.direct_expense.balance);
        let cogs1 = Number(cogs) - Number(Cl);
        let gp = Number(sale) - Number(cogs1);
        let data1 = [
          ["", "Opening Stock", Op],
          ["Add:", "Purchases", getData.purchase.balance],
          ["", "Direct Expense", getData.direct_expense.balance],
          ["Less:", "Closing Stock", Cl],
        ]; //,["","Total Cost of Goods Sold(COGS)","Formula=OS+SP-CS"],["","Gross Profit","Formula=TS-COGS"]]
        const headers1 = [["Less:-Cost of Goods Sold", "", ""]];
        let content1 = {
          startY: doc.lastAutoTable.finalY + 10,
          startX: 10,
          styles: { halign: "left", cellWidth: 180 },
          head: headers1,
          body: data1,
        };
        doc.autoTable(content1);
        doc.setLineWidth(2);
        doc.line(
          390,
          doc.lastAutoTable.finalY + 5,
          550,
          doc.lastAutoTable.finalY + 5
        );
        doc.setFontSize(10);
        // doc.setFont(undefined,'')
        doc.text(
          "Total Cost of Goods Sold(COGS)",
          225,
          doc.lastAutoTable.finalY + 20,
          0,
          0
        );
        doc.text(`${cogs1}`, 405, doc.lastAutoTable.finalY + 20, 0, 0);
        doc.text("Gross profit", 225, doc.lastAutoTable.finalY + 40, 0, 0);
        doc.text(`${gp}`, 405, doc.lastAutoTable.finalY + 40, 0, 0);
        doc.line(
          390,
          doc.lastAutoTable.finalY + 50,
          550,
          doc.lastAutoTable.finalY + 50
        );
        let data2 = [
          ["", "Admin", getData.admin.balance],
          ["", "Selling", getData.selling.balance],
          ["", "Financial cost", getData.financial_cost.balance],
          ["", "Other", getData.other_expense.balance],
        ]; //,["Total of operating Expense","","Formula=A+S+F+O+O"],["Profit before Tax","","Formula=GP-TOP"],["TAX EXPENSE","",""],["NET PROFIT","","Formula=PBT-TAX"]]
        const headers2 = [["Operating Expenses", "", ""]];
        let content2 = {
          startY: doc.lastAutoTable.finalY + 60,
          startX: 10,
          styles: { halign: "left", cellWidth: 180 },
          head: headers2,
          body: data2,
        };
        const te = getData.tax.balance;
        const tpe =
          Number(getData.admin.balance) +
          Number(getData.selling.balance) +
          Number(getData.financial_cost.balance) +
          Number(getData.other_expense.balance);
        const pbt = Number(gp) - Number(tpe);
        const NetProfit = Number(pbt) - Number(te);
        doc.autoTable(content2);
        doc.setLineWidth(2);
        doc.line(
          390,
          doc.lastAutoTable.finalY + 5,
          550,
          doc.lastAutoTable.finalY + 5
        );
        doc.setFontSize(10);
        doc.text(
          "Total of operating Expense",
          225,
          doc.lastAutoTable.finalY + 20,
          0,
          0
        );
        doc.text(`${tpe}`, 405, doc.lastAutoTable.finalY + 20, 0, 0);
        doc.text("Profit before Tax", 225, doc.lastAutoTable.finalY + 40, 0, 0);
        doc.text(`${pbt}`, 405, doc.lastAutoTable.finalY + 40, 0, 0);
        doc.text("TAX EXPENSE", 225, doc.lastAutoTable.finalY + 60, 0, 0);
        doc.text(`${te}`, 405, doc.lastAutoTable.finalY + 60, 0, 0);
        doc.text("NET PROFIT", 225, doc.lastAutoTable.finalY + 80, 0, 0);
        doc.text(`${NetProfit}`, 405, doc.lastAutoTable.finalY + 80, 0, 0);
        doc.setLineWidth(2);
        doc.line(
          390,
          doc.lastAutoTable.finalY + 90,
          550,
          doc.lastAutoTable.finalY + 90
        );
        doc.setProperties({
          title: `Balance Sheet Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });
        //ct doc.output('dataurlnewwindow')
        doc.save(
          `Profit Loss Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );
        dispatch(successGenerateBalanceSheet(getData));
      })
      .catch((error) => {
        dispatch(errorGenerateBalanceSheet());
      });
  };
};

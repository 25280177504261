import React, { useState } from 'react'
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { addEvent, getEvent, deleteEvent, getDashboardMarquee, /*UpdateHREvent */} from './MarqueeDetails.actions';
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { useEffect } from 'react';
import Swal from 'sweetalert';
import { isEmpty } from "lodash";
import { Scheduler } from "@aldabil/react-scheduler";
const EVENTS= [
  {
    booking_id: 3,
    title: "ALI",
    event_id: 2,
    start: new Date(`2023-02-11T03:00`),
    end: new Date(`2023-02-11T07:30`),
  },
  {
    booking_id: 3,
    title: "ALI",
    event_id: 2,
    start: new Date(`2023-02-12T03:00`),
    end: new Date(`2023-02-1T07:30`),
  },
]
  
const Dashboard = (props) => {
    const [eventName, setEventName] = useState();
    const [eventDescription, setEventDescription] = useState();
    const [submit, setSubmit] = useState(false);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const eventData = isEmpty(props.eventList) ? [] : props.eventList.reverse();
    const [state, setState] = React.useState({
        columns: [
            { title: 'Event Name', field: 'event_name' },
            { title: 'Event Description', field: 'event_desc' },
        ],
    });
    const clearStates = () => {
        setEventName();
        setEventDescription();
        setSubmit(false);

    }
    const handleVisible = () => {
        setAlertVisible(true)
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }

    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }

    useEffect(() => {
        props.getEvent(2);
        props.getDashboardMarquee();
        console.log(props.eventList,"be")
        console.log(props.dashboardMarqueeList,"bd")
    }, []);
    return (
        <>
            {(props.isFetchingEventData || props.isFetchingDashboardMarquee) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <Scheduler events={props.dashboardMarqueeList} />;
                </Container>
            )}
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addEvent: (data, handleVisible, handleDangerVisible) => dispatch(addEvent(data, handleVisible, handleDangerVisible)),
        getEvent: (id) => dispatch(getEvent(id)),
        //UpdateHREvent: (newData, oldData) => dispatch(UpdateHREvent(newData, oldData)),
        deleteEvent: (event_id) => dispatch(deleteEvent(event_id)),
        getDashboardMarquee:()=> dispatch(getDashboardMarquee()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingEventData: state.MarqueeReducer.isFetchingEventData,
    isAddingEventData: state.MarqueeReducer.isAddingEventData,
    eventList: state.MarqueeReducer.eventList,
    dashboardMarqueeList: state.MarqueeReducer.dashboardMarqueeList,
    isFetchingDashboardMarquee:state.MarqueeReducer.isFetchingDashboardMarquee,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);


import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import ImageUploader from 'react-images-upload';
import { addCatalogCall, getCatalogCall } from "./production.actions";

const Catelog = (props) => {

    const [catalog, setCatalog] = useState("");
    const [frontImages, setFrontImages] = useState();
    const [backImages, setBackImages] = useState();

   /* const [state, setState] = useState({
        columns: [
            { title: 'Catalog #', field: 'name' },
            // { title: 'Brand Description', field: 'description' },
        ],
    });*/

    const onDrop = (pictureFiles, pictureDataURLs) => {
        setFrontImages(pictureFiles);
    }

    const onDropBack = (pictureFilesBack, pictureDataURLs) => {
        setBackImages(pictureFilesBack);
    }


    function sendData() {
        props.addCatalogCall({
            name: catalog,
            imagefront: frontImages,
            imageback: backImages,
        });
    };

    console.log(frontImages, "kkjj")

    useEffect(() => {
        props.getCatalogCall();
    }, []);

    return (
        <Container fluid>
            <div className="main">

                <div>
                    <Row>
                        <Col lg="12" md="12" xs="12">
                            <Form.Group className="mb-3">
                                <Form.Label><b>Enter Catalog #:</b></Form.Label>
                                <Form.Control type="text" id="catalogDetail" placeholder="Catalog #...."
                                    onBlur={(e) => setCatalog(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <div>
                        <Row>
                            <Col lg="6" md="12" xs="12">
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Choose Front Pic:</b></Form.Label>
                                    <ImageUploader
                                        withIcon={true}
                                        buttonText='Choose Front Pic'
                                        onChange={onDrop}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                        maxFileSize={999999999999999999999}
                                        singleImage={true}
                                        withPreview={true}
                                    />
                                    
                                </Form.Group>
                            </Col>

                            <Col lg="6" md="12" xs="12">
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Choose Back Pic:</b></Form.Label>
                                    <ImageUploader
                                        withIcon={true}
                                        buttonText='Choose Back Pic'
                                        onChange={onDropBack}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                        maxFileSize={999999999999999999999}
                                        singleImage={true}
                                        withPreview={true}
                                    />
                                    
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>


                    {/* <div>
                        <Row>
                            <Col lg="12" md="12" xs="12">
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Choose Back Pic:</b></Form.Label>
                                    <ImageUploader
                                        withIcon={true}
                                        buttonText='Choose Back Pic'
                                        onChange={onDropBack}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                        maxFileSize={5242880}
                                        singleImage={true}
                                        withPreview={true}
                                    />
                                    
                                </Form.Group>
                            </Col>
                        </Row>
                    </div> */}

                    <div className="sendDiv">
                        <Button className="sendButton" onClick={() => sendData()}>Save</Button>
                    </div>

                    <MaterialTable
                        title="Catalog Details"
                        columns={[
                            { title: 'Catalog #', field: 'name' },
                            // { title: 'Brand Description', field: 'description' },
                        ]}
                        data={props.catalogInfo}
                        options={{
                            actionsColumnIndex: -1,
                            filtering: true,
                            exportButton: true,
                            exportAllData: true,
                            headerStyle: {
                                position: 'sticky', top: 0,
                                color: '#00BBBB',
                                fontWeight: '550',
                                onRowAdd: 'none',
                            },

                        }}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        // props.updateWareHouse(newData, oldData)
                                    }, 600);
                                }),

                            onRowDelete: (oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        //   props.deleteWareHouse(oldData.wareHouseId);
                                    }, 600);
                                }),
                        }}
                    />
                    {/* </Form> */}

                </div>

            </div>

        </Container>

    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addCatalogCall: (data) => dispatch(addCatalogCall(data)),
        getCatalogCall: () => dispatch(getCatalogCall()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    catalogInfo: state.productionReducer.catalogInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(Catelog);
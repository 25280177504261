import React, { useState } from 'react'
import { Container, Form, Row, Col, Button, FormGroup } from 'react-bootstrap';
import { addExpense, getExpense, deleteExpense,getBooking, getMasterControl,/*UpdateHRExpense */ 
SearchMarqueeExpenseReport,
getBookingById} from './MarqueeDetails.actions';
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { useEffect } from 'react';
import Swal from 'sweetalert';
import { isEmpty, find } from "lodash";
import Select from 'react-select';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";

const ReportMarquee = (props) => {
    const [menuName, setExpenseName] = useState("");
    const [bookingId, setBookingId] = useState("");
    const [bookingName, setBookingName] = useState("");
    const handleAddInput = () => {
        setInputField([...inputField, { master_control_item_id: "", expense_form_data_price: "" }])
    };

    const handleRemoveInput = (index) => {
        const list = [...inputField];
        list.splice(index, 1);
        setInputField(list);
    };
    const handleChange = (e, index, type) => {
        let z = find(props.allInventory, x => x.master_control_item_id == e.value);
        const { name, value } = e;
        const list = [...inputField];
        if (type == 'intname') list[index]['master_control_item_id'] = e.value;
        if (type == 'intname') list[index]['inventoryLabel'] = e.label;
        // if (type == 'intname') setMaximumQuantity(Number(z.currentBlnc));
        if (type == 'intprice') list[index]['expense_form_data_price'] = Number(e.target.value);
        setInputField(list);
        // list[index]['expense_form_data_price'] = "";
    };

    const [inputField, setInputField] = useState([{
        master_control_item_id: "",
        expense_form_data_price: 0,
    }]);
    
    const [expenseDescription, setExpenseDescription] = useState();

    const [submit, setSubmit] = useState(false);

    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function uploadExpense(id) {
       props.SearchMarqueeExpenseReport(id)
    }
    const expenseData = isEmpty(props.expenseList) ? [] : props.expenseList.reverse();
    const inventory = props.masterControlsList.map((x) => { let data = { value: x.master_control_item_id, label: x.master_control_item_name }; return data })

    const [state, setState] = React.useState({
        columns: [
            { title: 'Bookig Name', field: 'booking.booking_name' },
            { title: 'Expense Description', field: 'expense_desc' },
        ],
    });
    const [stateDishes, setStateDishes] = React.useState({
        columns: [
            { title: 'Dish Name', field: 'master_control_item.master_control_item_name' },
            { title: 'Price', field: 'expense_form_data_price' },
        ],
    });
    const clearStates = () => {
        setExpenseName();
        setExpenseDescription();
        setSubmit(false);

    }
    const handleVisible = () => {
        setAlertVisible(true)
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: "Added successfully...",
            icon: "success",
            button: "Ok",
        });
    }
    const handleChangeBooking = (selectedOption) =>
    {
       console.log(selectedOption, "sop")
        setBookingId(selectedOption.value);
        setBookingName(selectedOption.label);

    }
    const getBookingList = !isEmpty(props.bookingList) && (props.bookingList.map((x) => { let data = { value: x.booking_id, label: x.booking_name }; return data;   })) ;
    console.log('getBookiList', getBookingList, props.bookingList);
    const handleDangerVisible = () => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: "Something went wrong...",
            icon: "error",
            button: "Ok",
        });
    }
    const handleChangeBookingById = (selectedOption) =>
    {
       console.log(selectedOption, "sop")
        setBookingId(selectedOption.value);
       // setBookingName(selectedOption.label);
       // props.getBookingById(selectedOption.value)
        
    }

    useEffect(() => {
        props.getExpense();
        props.getMasterControl(1);
        props.getBooking();

    }, []);
    return (
        <>
            {(props.isFetchingExpenseData || props.isAddingExpenseData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />

                    </Button>
                </div>
            ) : (

                <Container fluid>
                    <Form>
                    <Row>
                                    <Col lg="12" md="12" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Select booking:</b></Form.Label>
                                            <Select
                                            isDisabled={isEmpty(props.bookingList)}
                                                placeholder="Select booking..."
                                                onChange={handleChangeBookingById}
                                                options={getBookingList}
                                            />

                                        </Form.Group>
                                    </Col>
                                </Row>
                        <div className="sendDiv">
                            {bookingId==""?
                            <Button className="sendButton" disabled style={{backgroundColor:'black'}} onClick={() => { uploadExpense(bookingId); setSubmit(true); }} >Generate Report</Button>:
                            <Button className="sendButton" onClick={() => { uploadExpense(bookingId); setSubmit(true); }} >Generate Report</Button>}
                        </div>
                    </Form>
                </Container>
            )}
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        addExpense: (data, handleVisible, handleDangerVisible) => dispatch(addExpense(data, handleVisible, handleDangerVisible)),
        getExpense: (id) => dispatch(getExpense(id)),
        getMasterControl: (id) => dispatch(getMasterControl(id)),
        getBooking: () => dispatch(getBooking()),
       // UpdateHRExpense: (newData, oldData) => dispatch(UpdateHRExpense(newData, oldData)),
        deleteExpense: (menu_id) => dispatch(deleteExpense(menu_id)),
        SearchMarqueeExpenseReport:(id)=>dispatch(SearchMarqueeExpenseReport(id)),
        getBookingById:(id) => dispatch(getBookingById(id)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    departmentList: state.MarqueeReducer.departmentList,
    isFetchingExpenseData: state.MarqueeReducer.isFetchingExpenseData,
    isAddingExpenseData: state.MarqueeReducer.isAddingExpenseData,
    expenseList: state.MarqueeReducer.expenseList,
    masterControlsList: state.MarqueeReducer.masterControlsList,
    bookingList: state.MarqueeReducer.bookingList,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportMarquee);


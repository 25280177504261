import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import { addColor, getColorCall } from "./production.actions";

const AddColor = (props) => {

  console.log(props.colorInfo, "ppll")

  const [color, setColor] = useState("");
  const [description, setDescription] = useState("");

  /*const [state, setState] = useState({
    columns: [
      { title: 'Color', field: 'colorName'},
      { title: 'Color Description', field: 'colorDescription' },
    ],
  });*/

  useEffect(() => {
    props.getColorCall();
  }, []);

  const sendData = () => {
    props.addColor({
      colorName: color,
      colorDescription: description,
    })
  }

  return (
        <Container fluid>
          <div className="main">

            <div>
              <Form>
                <Row>
                  <Col lg="12" md="12" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label><b>Enter Color:</b></Form.Label>
                      <Form.Control type="text" id="warehouseName" placeholder="Color...."
                      onBlur={(e)=> setColor(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" md="12" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label><b>Description:</b></Form.Label>
                      <Form.Control type="text" id="colorDescription" placeholder="Description...."
                      onBlur={(e)=> setDescription(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="sendDiv">
                 <Button className="sendButton" onClick={() => sendData()}>Save</Button>
                </div>

                <MaterialTable
                  title="Color Details"
                  columns={[
                    { title: 'Color', field: 'colorName'},
                    { title: 'Color Description', field: 'colorDescription' },
                  ]}
                  data={props.colorInfo}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    exportButton: true,
                    exportAllData: true,
                    headerStyle: {
                      position: 'sticky', top: 0,
                      color: '#00BBBB',
                      fontWeight: '550',
                      onRowAdd: 'none',
                    },

                  }}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                            // props.updateWareHouse(newData, oldData)
                        }, 600);
                      }),
                      
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                        //   props.deleteWareHouse(oldData.wareHouseId);
                        }, 600);
                      }),
                  }}
                />
              </Form>

            </div>

          </div>

        </Container>

      )}

const mapDispatchToProps = (dispatch) => {
  return {
    addColor: (data) => dispatch(addColor(data)),
    getColorCall: () => dispatch(getColorCall()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  colorInfo: state.productionReducer.colorInfo,
});

export default connect(mapStateToProps,mapDispatchToProps)(AddColor);
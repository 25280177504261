import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import {
  addMasterControl,
  getMasterControl,
  deleteMasterControl,
  UpdateMasterControl /*UpdateHREvent */,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import Select from 'react-select';
import jsPDF from "jspdf";
import { isEmpty } from "lodash";
import logo from "../../../../../Images/logo.png";

const AddMasterControls = (props) => {
  const [eventName, setEventName] = useState("");
  const [type, setType] = useState("");
  const [typeName, setTypeName] = useState("");
  const [eventDescription, setEventDescription] = useState("");

  const [submit, setSubmit] = useState(false);

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  function uploadEvent() {
    props.addMasterControl(
      {
        master_control_item_name: eventName,
        master_control_item_desc: eventDescription,
        master_control_item_type:typeName,
      },
      handleVisible,
      handleDangerVisible
    );
  }
  const masterControlData = [{
    id:1,
    value:"Dish"
  },{
    id:"2",
    value:"Decor"
  }
]
    const getMasterControlData = masterControlData.map((x) => { let data = { value: x.id, label: x.value }; return data })
    const handleChangeType = (selectedOption) => {
      setType(selectedOption.value);
      setTypeName(selectedOption.label);
      
  };
  const clearStates = () => {
    setEventName("");
    setEventDescription("");
    setType("");
    setTypeName("");
    setSubmit(false);
  };
  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  return (
    <>
      {props.isAddingMasterControlData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col lg="6" md="6" xs="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter Master Control Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Event Name...."
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg="6" md="6" xs="6">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Select Master Control Type *</b></Form.Label>
                                        <Select
                                        value={{label:typeName}}
                                            placeholder="Select Master Control Type..."
                                            onChange={handleChangeType}
                                            options={getMasterControlData}
                                        />
                                    </Form.Group>
                                </Col>
            </Row>

            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Enter Master Control Description:</b>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Master Control Description....."
                    value={eventDescription}
                    onChange={(e) => setEventDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {isEmpty(eventName)||type=="" ? (
                <Button
                  className="sendButton"
                  style={{ backgroundColor: "black" }}
                  onClick={() => {
                    uploadEvent();
                    setSubmit(true);
                  }}
                  disabled
                >
                  Save
                </Button>
              ) : (
                <Button
                  className="sendButton"
                  onClick={() => {
                    uploadEvent();
                    setSubmit(true);
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addMasterControl: (data, handleVisible, handleDangerVisible) =>
      dispatch(addMasterControl(data, handleVisible, handleDangerVisible)),
    getMasterControl: (id) => dispatch(getMasterControl(id)),
    UpdateMasterControl: (newData, oldData) =>
      dispatch(UpdateMasterControl(newData, oldData)),
    deleteMasterControl: (event_id) => dispatch(deleteMasterControl(event_id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingMasterControlData: state.MarqueeReducer.isFetchingMasterControlData,
  isAddingMasterControlData: state.MarqueeReducer.isAddingMasterControlData,
  masterControlsList: state.MarqueeReducer.masterControlsList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMasterControls);

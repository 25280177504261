import React,{useState} from 'react'
import { getBooking, getBookingById, getCustomerById, getCustomerMarquee, SearchMarqueeEventReport } from './MarqueeDetails.actions';
import { connect } from "react-redux";
import { Container, Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import { isEmpty, find, sumBy, pick } from "lodash";
import Select from "react-select";
import { useEffect } from "react";
import MaterialTable from 'material-table';
const InstallmentHistory = (props) => {
  function ViewHistoy(id) {
    props.getCustomerById(id)
    console.log(props.customerByIdList,'check')
 }
 const subEventsData = isEmpty(props.customerByIdList) ? [] : props.customerByIdList;
 const [customerId, setCustomerId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const handleChangeCustomer = (selectedOption) => {
    console.log(selectedOption, "sop");
    setCustomerId(selectedOption.value);
    setCustomerName(selectedOption.label);
  };
 const getCustomerMarqueeList =
 !isEmpty(props.customerMarqueeList) &&
 props.customerMarqueeList.map((x) => {
   let data = {
     value: x.customer_id,
     label: `${x.customer_name}/${x.customer_cnic}`,
   };
   return data;
 });
  
  useEffect(() => {
    props.getCustomerMarquee();
  }, []);
  return (
    <>
      <Container fluid>
          <Form>
          <Row>
                  <Col lg="12" md="12" xs="12">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Customer *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.customerMarqueeList)}
                        placeholder="Select Customer..."
                        onChange={handleChangeCustomer}
                        options={getCustomerMarqueeList}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                                <div className="sendDiv">
                                  {isEmpty(customerId)?
                            <Button className="sendButton" disabled style={{backgroundColor:'black'}} onClick={() => { ViewHistoy(customerId);}} >View History</Button>:
                            <Button className="sendButton" onClick={() => { ViewHistoy(customerId);}} >View History</Button>}
                        </div>
                        <MaterialTable 
                                    title="Installment History"
                                    columns={[
                                        { title: 'Installment Id', field: 'booking_installment_id' },
                                        { title: 'Total Price', field: 'booking_total_amount' },
                                        { title: 'Installment Price', field: 'installment_price', editable: 'never' },
                                        { title: 'Remaining Price', field: 'closing_balance', editable: 'never' },
                                    ]}
                                    data={subEventsData}
                                    options={{
                                        actionsColumnIndex: -1,
                                        filtering: true,
                                        exportButton: true,
                                        exportAllData: true,
                                        headerStyle: {
                                            position: 'sticky', top: 0,
                                            color: '#00BBBB',
                                            fontWeight: '550',
                                            onRowAdd: 'none',
                                        },

                                    }}
                                 /*   editable={{
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve) => {
                                                setTimeout(() => {
                                                    resolve();
                                                    {

                                                        props.updateSubEvent(newData, oldData)
                                                    }
                                                }, 600);
                                            }),
                                        onRowDelete: (oldData) =>
                                            new Promise((resolve) => {
                                                setTimeout(() => {
                                                    resolve();
                                                    props.deleteSubEvent(oldData.sub_event_id);
                                                }, 600);
                                            }),
                                    }}*/
                                    />
          </Form>
        </Container>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
   // getBooking: () => dispatch(getBooking()),
   // getBookingById:(id) => dispatch(getBookingById(id)),
    getCustomerMarquee: () => dispatch(getCustomerMarquee()),
    getCustomerById:(id)=>dispatch(getCustomerById(id)),
  //  SearchMarqueeEventReport:(bookingByIdList)=> dispatch(SearchMarqueeEventReport(bookingByIdList)),
  };
};
const mapStateToProps = (state, ownProps) => ({
 // bookingList: state.MarqueeReducer.bookingList,
 // bookingByIdList: state.MarqueeReducer.bookingByIdList,
 customerByIdList:state.MarqueeReducer.customerByIdList,
 isFetchingCustomerByIdData:state.MarqueeReducer.isFetchingCustomerByIdData,
 customerMarqueeList: state.MarqueeReducer.customerMarqueeList,
});

export default connect(mapStateToProps, mapDispatchToProps)(InstallmentHistory);


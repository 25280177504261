import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import 'jspdf-autotable';
import { addSizeCall, getSizeCall } from "./production.actions";

const AddSize = (props) => {
  console.log(props.sizeInfo, "qqpp")
  const [size, setSize] = useState("");
  const [description, setDescription] = useState("");

 /* const [state, setState] = useState({
    columns: [
      { title: 'Size', field: 'sizeName', render: rowData => rowData.sizeName},
      { title: 'Size Description', field: 'sizeDescription' },
    ],
  });*/

  function sendData() {
    props.addSizeCall({
      sizeName: size,
      sizeDescription: description,
    });
  };

  useEffect(() => {
    props.getSizeCall();
  }, []);

  return (
        <Container fluid>
          <div className="main">

            <div>
              <Form>
                <Row>
                  <Col lg="12" md="12" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label><b>Enter Size:</b></Form.Label>
                      <Form.Control type="text" id="sizeDetail" placeholder="Size...."
                      onBlur={(e)=> setSize(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" md="12" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label><b>Description:</b></Form.Label>
                      <Form.Control type="text" id="sizeDescription" placeholder="Description...."
                      onBlur={(e)=> setDescription(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="sendDiv">
                 <Button className="sendButton" onClick={() => sendData()}>Save</Button>
                </div>

                <MaterialTable
                  title="Size Details"
                  columns={[
                    { title: 'Size', field: 'sizeName', render: rowData => rowData.sizeName},
                    { title: 'Size Description', field: 'sizeDescription' },
                  ]}
                  data={props.sizeInfo}
                  options={{
                    actionsColumnIndex: -1,
                    filtering: true,
                    exportButton: true,
                    exportAllData: true,
                    headerStyle: {
                      position: 'sticky', top: 0,
                      color: '#00BBBB',
                      fontWeight: '550',
                      onRowAdd: 'none',
                    },

                  }}
                  editable={{
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                            // props.updateWareHouse(newData, oldData)
                        }, 600);
                      }),
                      
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                        //   props.deleteWareHouse(oldData.wareHouseId);
                        }, 600);
                      }),
                  }}
                />
              </Form>

            </div>

          </div>

        </Container>

      )}

const mapDispatchToProps = (dispatch) => {
  return {
    addSizeCall : (data) => dispatch(addSizeCall(data)),
    getSizeCall : () => dispatch(getSizeCall()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  sizeInfo: state.productionReducer.sizeInfo,
});

export default connect(mapStateToProps,mapDispatchToProps)(AddSize);
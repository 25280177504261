import React, { useEffect, useState } from "react";
import { Button, Container, Col, Row, Form } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import jsPDF from "jspdf";
import moment from "moment";
import { getProfitByDate } from "./MarqueeDetails.actions";
import logo from "../../../../../Images/logo.png";
import Swal from "sweetalert";

const MonthlyProfit = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  const exportPDF = () => {
    props.getProfitByDate({
      startedDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    });
  };

  useEffect(() => {}, []);

  return (
    <>
      {props.isFetchingEmployeeData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingEmployeeData ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Start Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col lg="4" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> End Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <div className="sendDiv" style={{ marginTop: "15px" }}>
              {endDate == "" || startDate == "" ? (
                <Button
                  className="sendButton"
                  disabled
                  style={{ backgroundColor: "black" }}
                  onClick={() => exportPDF()}
                >
                  Generate
                </Button>
              ) : (
                <Button className="sendButton" onClick={() => exportPDF()}>
                  Generate
                </Button>
              )}
            </div>
            <div>
              <MaterialTable
                title="Profit By Booking"
                columns={[
                  { title: "Total Cost", field: "total_cost" },
                  { title: "Total Expense", field: "total_expense" },
                  { title: "Total Profit", field: "total_profit" },
                ]}
                data={[props.profitByDate]}
                options={{
                  actionsColumnIndex: -1,
                  filtering: false,
                  exportButton: true,
                  paging: true,
                  pageSize: 5, // make initial page size
                  emptyRowsWhenPaging: false, // To avoid of having empty rows
                  pageSizeOptions: [50, 100, 150, 200],
                  exportPdf: (columns, data) => {
                    const doc = new jsPDF();

                    const columnTitles = columns.map(
                      (columnDef) => columnDef.title
                    );

                    const pdfData = data.map((rowData) =>
                      columns.map(
                        (columnDef) => (
                          console.log(rowData[columnDef.field], "eeeee"),
                          columnDef.field == "createdAt"
                            ? moment(rowData[columnDef.field]).format(
                                "YYYY-MM-DD"
                              )
                            : rowData[columnDef.field]
                        )
                      )
                    );
                    let content = {
                      startY: 50,
                      startX: 5,
                      head: [columnTitles],
                      body: pdfData,
                    };
                    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                    const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                    const companyNTN = `STRN#${process.env.REACT_APP_COMPANY_NTN}`;

                    doc.addImage(logo, "PNG", 10, 11, 30, 30);
                    // doc.text(`Company Name: ${companyName}`, 75, 20, 0, 20);

                    doc.setFont("Times New Roman", "bolditalic");
                    // doc.setFontT("bolditalic");
                    doc.text(companyName, 75, 15, 0, 20);
                    doc.setFontSize(9);
                    doc.setFont("Times New Roman", "normal");
                    doc.text(companyAddress, 75, 20, 0, 20);
                    doc.text(companyNTN, 75, 25, 0, 20);
                    doc.setFontSize(14);
                    doc.setFont("Times New Roman", "normal");
                    doc.text("Datewise Sale Report", 75, 46, 0, 20);
                    doc.autoTable(content);

                    doc.save(`ByDateProfitReport.pdf`);
                  },
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#00BBBB",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    //     addExpense: (data, handleVisible, handleDangerVisible) => dispatch(addExpense(data, handleVisible, handleDangerVisible)),
    //     getExpense: (id) => dispatch(getExpense(id)),
    //     getMasterControl: (id) => dispatch(getMasterControl(id)),
    //     getBooking: () => dispatch(getBooking()),
    //    // UpdateHRExpense: (newData, oldData) => dispatch(UpdateHRExpense(newData, oldData)),
    //     deleteExpense: (menu_id) => dispatch(deleteExpense(menu_id)),
    //     SearchMarqueeExpenseReport:(id)=>dispatch(SearchMarqueeExpenseReport(id)),
    //     getBookingById:(id) => dispatch(getBookingById(id)),
    getProfitByDate: (data) => dispatch(getProfitByDate(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  // departmentList: state.MarqueeReducer.departmentList,
  // isFetchingExpenseData: state.MarqueeReducer.isFetchingExpenseData,
  // isAddingExpenseData: state.MarqueeReducer.isAddingExpenseData,
  // expenseList: state.MarqueeReducer.expenseList,
  // masterControlsList: state.MarqueeReducer.masterControlsList,
  // bookingList: state.MarqueeReducer.bookingList,
  isFetchingprofitByDate: state.MarqueeReducer.isFetchingprofitByDate,
  profitByDate: state.MarqueeReducer.profitByDate,
});

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyProfit);

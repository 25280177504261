import React, { useState } from "react";
import {
  Container,
  Form,
  Row,
  Col,
  Button,
  FormGroup,
  FormControl,
} from "react-bootstrap";
import {
  addMenu,
  getMenu,
  deleteMenu,
  getMasterControl /*UpdateHRMenu */,
  SearchMarqueeMenuReport,
  UpdateMenu,
} from "./MarqueeDetails.actions";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { useEffect } from "react";
import Swal from "sweetalert";
import { isEmpty, find, sumBy } from "lodash";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { IconButton } from "@material-ui/core";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
const AddMenu = (props) => {
  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { master_control_item_id: "", menu_data_price: "" },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };
  const handleChange = (e, index, type) => {
    let z = find(
      props.allInventory,
      (x) => x.master_control_item_id == e.value
    );
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["master_control_item_id"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    // if (type == 'intname') setMaximumQuantity(Number(z.currentBlnc));
    if (type == "intprice")
      list[index]["menu_data_price"] = Number(e.target.value);
    setInputField(list);
    // list[index]['menu_data_price'] = "";
  };

  const [inputField, setInputField] = useState([
    {
      master_control_item_id: "",
      menu_data_price: 0,
    },
  ]);
  const [menuName, setMenuName] = useState("");
  const [menuDescription, setMenuDescription] = useState("");

  const [submit, setSubmit] = useState(false);

  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  function uploadMenu() {
    console.log(inputField);
    let totalPrice = sumBy(inputField, "menu_data_price");
    console.log("price is" + totalPrice);
    props.addMenu(
      {
        menu_name: menuName,
        menu_price: totalPrice,
        menu_desc: menuDescription,
        dishes: inputField,
      },
      handleVisible,
      handleDangerVisible
    );
  }
  const menuData = isEmpty(props.menuList) ? [] : props.menuList.reverse();
  const inventory = props.masterControlsList.map((x) => {
    let data = {
      value: x.master_control_item_id,
      label: x.master_control_item_name,
    };
    return data;
  });

  const [state, setState] = React.useState({
    columns: [
      { title: "Menu Name", field: "menu_name" },
      { title: "Menu Price", field: "menu_price" },
      { title: "Menu Description", field: "menu_desc" },
    ],
  });
  /*const [stateDishes, setStateDishes] = React.useState({
        columns: [
            { title: 'Dish Name', field: 'master_control_item.master_control_item_name' },
            { title: 'Price', field: 'menu_data_price' },
        ],
    });*/
  const clearStates = () => {
    setMenuName("");
    setMenuDescription("");
    setInputField([
      {
        master_control_item_id: "",
        menu_data_price: 0,
      },
    ]);
    setSubmit(false);
  };
  const handleVisible = () => {
    setAlertVisible(true);
    clearStates();
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };
  function MenuPDF(data) {
    props.SearchMarqueeMenuReport(data);
  }
  useEffect(() => {
    props.getMenu(2);
    props.getMasterControl(1);
  }, []);
  return (
    <>
      {props.isFetchingMenuData || props.isAddingMenuData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Enter Menu Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Menu Name...."
                    onChange={(e) => setMenuName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="12" lg="12" md="12" sm="12" xs="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Enter Menu Description:</b>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Menu Description....."
                    onBlur={(e) => setMenuDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            {inputField.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <FormGroup>
                    <Row>
                      <Col xl="6" lg="6" md="12" sm="12">
                        <Form.Group>
                          <Form.Label>
                            <b> Select Dish*</b>
                          </Form.Label>
                          <Select
                            style={{ zIndex: 1 }}
                            value={{ label: item.inventoryLabel }}
                            placeholder="Select Items..."
                            name="master_control_item_id"
                            onChange={(e) => handleChange(e, i, "intname")}
                            options={inventory}
                            isOptionDisabled={(option) =>
                              !isEmpty(
                                find(
                                  inputField,
                                  (x) =>
                                    x.master_control_item_id == option.value
                                )
                              )
                            }
                          />
                        </Form.Group>
                      </Col>

                      <Col xl="3" lg="3" md="6" sm="6">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <b>Add Price*</b>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Add Price...."
                            name="menu_data_price"
                            value={item.menu_data_price}
                            onChange={(e) => handleChange(e, i, "intprice")}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="3" style={{ marginTop: 25 }}>
                        {inputField.length - 1 == i && (
                          <IconButton
                            title="Click to Add Demand"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              color: "black",
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        )}

                        {inputField.length !== 1 && (
                          <IconButton
                            title="Click to Remove Demand"
                            onClick={() => handleRemoveInput(i)}
                            style={{ float: "right", color: "black" }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <div className="sendDiv">
              {console.log(inputField, "testtttttttttttttttttttttttt")}
              {isEmpty(menuName) ||
              inputField[0].master_control_item_id == "" ||
              inputField[0].menu_data_price == 0 ? (
                <Button
                  className="sendButton"
                  style={{ backgroundColor: "black" }}
                  onClick={() => {
                    uploadMenu();
                    setSubmit(true);
                  }}
                  disabled
                >
                  Save
                </Button>
              ) : (
                <Button
                  className="sendButton"
                  onClick={() => {
                    uploadMenu();
                    setSubmit(true);
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addMenu: (data, handleVisible, handleDangerVisible) =>
      dispatch(addMenu(data, handleVisible, handleDangerVisible)),
    getMenu: (id) => dispatch(getMenu(id)),
    getMasterControl: (id) => dispatch(getMasterControl(id)),
    UpdateMenu: (newData, oldData) => dispatch(UpdateMenu(newData, oldData)),
    deleteMenu: (menu_id) => dispatch(deleteMenu(menu_id)),
    SearchMarqueeMenuReport: (data) => dispatch(SearchMarqueeMenuReport(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  departmentList: state.MarqueeReducer.departmentList,
  isFetchingMenuData: state.MarqueeReducer.isFetchingMenuData,
  isAddingMenuData: state.MarqueeReducer.isAddingMenuData,
  menuList: state.MarqueeReducer.menuList,
  masterControlsList: state.MarqueeReducer.masterControlsList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMenu);
